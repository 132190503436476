.home-main {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 100px 0px;
}

.dashboardIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed303b;
  height: 250px;
  width: 100%;
  border-radius: 7px;
}
.dashboardIconBox img {
  filter: brightness(0) invert(1);
}
.dashboardCard {
  cursor: pointer;
}

.dashboardCard p {
  font-size: 17px;
  color: #000000;
  margin: 0.5rem 0;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
}

.homePageBackBtn {
  position: absolute;
  top: 18px;
  left: 130px;
  background: #001140;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homePageBackBtn:hover {
  background: #001140ed;
}

.homePageBackBtn svg {
  color: #fff !important;
}
